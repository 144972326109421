export class Modal {
	constructor() {
		this.elements = this.getModalElements(); // Get modal elements
		this.setupEvents(); // Setup event listeners, initialize modal based on URL hash & detect OS for download modals
	}

	/**
	 * Get modal elements and organize them into an object
	 * @returns {Object} An object containing modal elements
	 */
	getModalElements() {
		const container = $("#sf-modal"); // Get the modal container element
		const dialog = {}; // Initialize an empty object to store modal dialogs
		// loop all the dialogs in the modal container:
		container.find("[data-modaldialog]").each(function () {
			const item = $(this); // Get the current modal dialog element
			const name = item.attr("data-modaldialog"); // Get the name of the modal dialog
			dialog[name] = item; // Store the modal dialog element in the dialog object
		});

		return {
			container, // set container
			dialog // set dialog
		};
	}

	/**
	 * Set up event listeners for various modal interactions and initialize modal based on URL hash
	 * @returns {void}
	 */
	setupEvents() {
		const self = this; // self reference for use in nested functions (to avoid conflict with 'this' keyword)

		$(document).on("click", "[data-modal]", function (e) {
			e.preventDefault(); // prevent default action
			let target = $(this).data("modal"); // get the target from the data attribute on the clicked element (either 'close' or the target dialog to show)

			// handle team member modal:
			if (target === "team") {
				const $member = $(this).closest("[data-teammember]"); // get the closest team member element from the clicked element
				if ($member.length) {
					// if the team member element exists...
					self.elements.dialog.team.html($member.html()); // ...duplicate the team member content to the team dialog...
				} else {
					target = "close"; // ...otherwise, set target to false (which will hide the modal)
				}
			}

			self.show(target); // show the target dialog (or hide if target is 'close')
		});

		this.handleHashFragment(); // handle the hash fragment in the URL on first page load
		$(window).on("hashchange", () => this.handleHashFragment()); // detect changes to hash and run handleHashFragment

		this.changeOSforDownloadModals(); // detect & set OS for download modals on first page load
		// handle manual changes of OS on download dialogs:
		$("[data-downloadlinksdropdown]").on("change", function () {
			const setOS = $(this).val(); // get the selected value
			self.changeOSforDownloadModals(setOS); // set OS for download modals
		});
	}

	/**
	 * Handle the hash fragment in the URL to show the appropriate modal on first page load
	 * @returns {void}
	 */
	handleHashFragment() {
		let hash = window.location.hash.substring(1); // get the hash fragment from the URL without the leading '#'
		if (hash) {
			hash = hash === "download" ? "spider-download" : hash === "support" ? "spider-support" : hash; // adjust outlier cases
			if (this.elements.dialog[hash]) {
				this.show(hash); // show the modal if the hash matches a valid dialog
			}
		}
	}

	/**
	 * Show or hide the modal container based on the provided target
	 * @param {string|boolean} target - The target dialog to show, or false to hide the modal
	 * @returns {void}
	 */
	show(target) {
		const targetExists = target && target !== "close" && this.elements.dialog[target]; // check if target exists and is valid

		// if target exists, show the modal, otherwise hide it
		targetExists ? this.elements.container.show() : this.elements.container.hide();

		// clear the hash when the modal is closed:
		window.location.hash = targetExists ? window.location.hash : "";

		// Show the targeted dialog and hide all others:
		for (let [key, item] of Object.entries(this.elements.dialog)) {
			targetExists && key == target ? item.show() : item.hide(); // If key matches target, show the item, otherwise hide the item
		}
	}

	/**
	 * Detect OS and apply it to download modals
	 * @param {string} setOS - The OS to set for download modals (optional)
	 * @returns {void}
	 */
	changeOSforDownloadModals(setOS = null) {
		const detectedOS = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase(); // Get the detected OS from the browser

		const os =
			setOS && ["mac", "linux", "windows"].includes(setOS.toLowerCase()) // if setOS is provided and is a valid OS...
				? setOS.toLowerCase() // ...set os to the provided OS...
				: detectedOS.startsWith("mac") // ...otherwise, if the detected OS starts with 'mac'...
				? "mac" // ...set os to mac...
				: detectedOS.startsWith("linux") // ...if the detected OS starts with 'linux'...
				? "linux" // ...set os to linux...
				: "windows"; // ...otherwise, set os to windows (default).

		$("[data-downloadlinks]").attr("data-downloadlinks", os); // apply os to download modals
		// if we're setting the OS...
		if (setOS) {
			// ...loops through all the dropdowns in each dialog...
			$("[data-downloadlinksdropdown]").each(function () {
				$(this).val(os); // ...and set the value of each dropdown to the provided OS.
			});
		}
	}
}
