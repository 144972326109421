import {CheckoutBillingDetails} from "../_classes";
import {ResponsiveRearrange, matchLocationPath} from "../sf-utils";
import {APP_ADMIN_AJAX_PATH} from "../constants";

export function initCheckoutBillingDetails() {
	const checkoutBillingDetails = new CheckoutBillingDetails({
		billing_first_name: ["hasValue"],
		billing_last_name: ["hasValue"],
		billing_email: ["hasValue", "isEmail"],
		billing_company: ["hasValue"],
		customer_reference: ["hasValue"],
		billing_country: [],
		billing_address_1: ["hasValue"],
		billing_address_2: ["hasValue"],
		billing_city: ["hasValue"],
		billing_state: ["hasValue"],
		billing_postcode: ["hasValue"],
		tax_id: ["hasCompletedTaxField"]
	});

	if ($("#place_order").length) {
		new ResponsiveRearrange({
			targets: "place_order",
			breakpoints: [{respondToMin: 750, destinations: "checkout-product-details"}]
		});
	}

	$(document).on("click", "[data-continue-with-vat]", (e) => {
		checkoutBillingDetails.refreshTaxIDField(true);
		window.sfModal.show(false);
	});

	$(document).on("click", "[data-notify-when-validated]", (e) => {
		$.ajax({
			type: "POST",
			url: APP_ADMIN_AJAX_PATH,
			data: {
				action: "notify_tax_id_validation"
			},
			success: function (response) {
				window.location.href = "/cart#confirm-vies-notification";
			},
			error: function (error) {
				alert("An error occurred, please contact us for assistance");
			}
		});

		window.sfModal.show(false);
	});
}
